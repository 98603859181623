import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useGlobalContext } from "../Config";
import { jsPDF } from "jspdf";
import { Dialog } from "primereact/dialog";
import "jspdf-autotable";
import { ProgressSpinner } from "primereact/progressspinner";
import moment from "moment-timezone";
import {
  generateNotaDeCobroPDF,
  generateEstadoDeCuentaPDF,
} from "../utilities/pdfGenerator";
import JSZip from "jszip";

const Estados = (props) => {
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const { styleProperties } = useGlobalContext();
  const [empresa, setEmpresa] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [anio, setAnio] = useState(moment().year());
  const [mes, setMes] = useState(moment().format("MM"));
  const [isLoading, setIsLoading] = useState(false);
  const [documentosData, setDocumentosData] = useState([]); // Estado para los documentos
  const [show1, setShow1] = useState(false);
  const [visibleDialog, setVisibleDialog] = useState(false); // Controla la visibilidad del dialog
  const [selectedDocument, setSelectedDocument] = useState(null); // Controla el documento seleccionado para mostrar en el dialog
  const [expandedRows, setExpandedRows] = useState(null); // Controla las filas expandidas

  const userId = sessionStorage.getItem("userId");

  const currentYear = new Date().getFullYear();
  const anios = Array.from({ length: currentYear - 2020 + 5 }, (_, i) => ({
    label: String(2020 + i),
    value: 2020 + i,
  }));
  const meses = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i); // Set date to month `i`
    return {
      label:
        date.toLocaleString("es", { month: "long" }).charAt(0).toUpperCase() +
        date.toLocaleString("es", { month: "long" }).slice(1),
      mes: String(i + 1).padStart(2, "0"), // Month number with leading zero
    };
  });

  const formatNumber = (number) => {
    if (typeof number !== "number") return number;
    return new Intl.NumberFormat("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const formatNumberUf = (number) => {
    if (typeof number !== "number") return number;
    return new Intl.NumberFormat("es-CL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No disponible";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };

  ///////////////////////////////////////////////////////// Fetchs /////////////////////////////////////////////////////////
  useEffect(() => {
    const fetchEmpresas = async () => {
      const endpoint = `${baseUrl}getEmpresasByUsuario?idUsuario=${userId}`;
      try {
        const response = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        });
        const data = await response.json();
        if (data.respuesta) {
          const empresasFetched = data.empresas.map((empresa) => ({
            label: empresa.nombre,
            id: empresa.id,
          }));
          setEmpresas(empresasFetched);
          setEmpresa(empresasFetched[0].id);
        }
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };
    fetchEmpresas();
  }, [baseUrl, userId]);

  // Fetch grupos al presionar "Buscar"
  const handleBuscar = async () => {
    setIsLoading(true);
    const endpoint = `${baseUrl}obtenerGrupos?idEmpresa=${empresa}&anio=${anio}&mes=${mes}`;
    try {
      setShow1(true);

      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });
      const data = await response.json();
      if (data.respuesta) {
        console.log(data.grupos);
        setDocumentosData(data.grupos);
      } else {
        console.error(
          "Error fetching grupos:",
          data.mensaje || "Error desconocido"
        );
      }
    } catch (error) {
      console.error("Error fetching grupos:", error);
    }
    setIsLoading(false);
  };

  const showDocumentDetails = (rowData) => {
    setSelectedDocument(rowData.documentos);
    setVisibleDialog(true);
  };

  const actionTemplate = (rowData) => {
    return (
      <div className="actions">
        <Button
          icon="pi pi-info"
          className="p-button-rounded p-button-info"
          onClick={() => showDocumentDetails(rowData)}
        />
        <Button
          icon="pi pi-file-pdf"
          className="p-button-rounded p-button-warning"
          onClick={() => exportToPDF(rowData)}
          style={{ marginLeft: "5px" }}
        />
        <Button
          icon="pi pi-download"
          className="p-button-rounded p-button-success"
          onClick={() => handleDownloadZip(rowData)}
          style={{ marginLeft: "5px" }}
        />
      </div>
    );
  };

  const handleDownloadZip = async (grupo) => {
    const zip = new JSZip();

    // 1. Validar que todos los documentos requeridos tengan adjuntos
    const facturasRequeridas = grupo.documentos.filter(
      (doc) =>
        doc.tipoDocumento.toLowerCase() === "factura afecta" ||
        doc.tipoDocumento.toLowerCase() === "factura exenta"
    );

    const faltantes = facturasRequeridas.filter(
      (doc) => !doc.adjuntos || doc.adjuntos.length === 0
    );

    console.log("aaa: ", facturasRequeridas);

    if (faltantes.length > 0) {
      alert(
        "No se puede descargar el archivo. Falta(n) la(s) factura(s) asociada(s) a los siguientes documentos:\n" +
          faltantes
            .map((doc) => `Documento N°: ${doc.idDocumento || "N/A"}`)
            .join("\n")
      );
      return;
    }

    // 2. Generar PDFs existentes
    const notaDeCobro = grupo.documentos.find(
      (doc) => doc.tipoDocumento.toLowerCase() === "nota de cobro"
    );
    if (notaDeCobro) {
      const notaBlob = generateNotaDeCobroPDF(
        baseUrl,
        notaDeCobro,
        formatNumber,
        formatNumberUf,
        formatDate,
        grupo.mes,
        grupo.anio
      );
      zip.file(`NotaDeCobro_${notaDeCobro.folio || "sin_folio"}.pdf`, notaBlob);
    }

    const estadoBlob = generateEstadoDeCuentaPDF(
      baseUrl,
      grupo,
      formatNumber,
      formatNumberUf,
      formatDate,
      grupo.mes,
      grupo.anio
    );
    zip.file(
      `EstadoDeCuenta_${grupo.numeroEstado || "sin_numero"}.pdf`,
      estadoBlob
    );

    // 3. Descargar los adjuntos y agregarlos al ZIP
    for (const documento of facturasRequeridas) {
      for (const adjunto of documento.adjuntos) {
        try {
          const response = await fetch(
            `${baseUrl}descargar/adjunto/documento?id=${documento.idDocumento}`,
            {
              method: "GET",
              credentials: "include",
              redirect: "follow",
              headers: { "Content-Type": "application/json" },
            }
          );
          if (!response.ok) {
            throw new Error(`Error descargando archivo ${adjunto.nombre}`);
          }

          const blob = await response.blob();
          zip.file(adjunto.nombre, blob);
        } catch (error) {
          console.error(
            `Error descargando archivo adjunto: ${adjunto.nombre}`,
            error
          );
        }
      }
    }

    // 4. Generar el ZIP y descargarlo
    const zipBlob = await zip.generateAsync({ type: "blob" });
    const link = document.createElement("a");
    link.href = URL.createObjectURL(zipBlob);
    link.download = `Documentos_${grupo.nombreCliente || "cliente"}_${
      grupo.descripcionGrupo || "documentos"
    }.zip`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  // Plantilla de expansión de fila para mostrar los detalles de cobro
  const rowExpansionTemplate = (data) => (
    <DataTable value={data.detallesCobro} responsiveLayout="scroll">
      <Column field="glosa" header="Descripción" />
      <Column field="unidadMedida" header="Unidad de Medida" />
      <Column field="cantidad" header="Cantidad" />
      <Column
        field="unitario"
        header="Precio Unitario"
        body={(rowData) => formatNumber(rowData.unitario)}
      />
    </DataTable>
  );

  const toggleRowExpansion = (rowData) => {
    setExpandedRows((prevExpandedRows) => {
      const expanded = prevExpandedRows ? [...prevExpandedRows] : [];
      const index = expanded.findIndex(
        (row) => row.idDocumento === rowData.idDocumento
      );
      if (index >= 0) {
        expanded.splice(index, 1); // Cierra la fila si ya está expandida
      } else {
        expanded.push(rowData); // Expande la fila si está cerrada
      }
      return expanded;
    });
  };

  const expandButtonTemplate = (rowData) => (
    <Button
      icon={
        expandedRows?.find((row) => row.idDocumento === rowData.idDocumento)
          ? "pi pi-angle-up"
          : "pi pi-angle-down"
      }
      className="p-button-rounded p-button-text"
      onClick={() => toggleRowExpansion(rowData)}
    />
  );

  ///////////////////////////////////////////////////////// GENERAR PDFs /////////////////////////////////////////////////////////

  const exportToPDF = (rowData) => {
    const pdf = new jsPDF("p", "mm", "a4");

    const marginLeft = 10;
    const marginTop = 10;

    // Datos básicos
    const clienteNombre = rowData.nombreCliente;
    const numeroEstado = rowData.numeroEstado;
    const clienteRUT = rowData.rutCliente;
    const empresaRUT = rowData.rutEmpresa;
    const inicio = rowData.inicioPago;
    const fin = rowData.finPago;
    const mesAnio = `${mes}-${anio}`;
    console.log(rowData);
    const documentoEjemplo = rowData.documentos[0];
    const fechaEmision = formatDate(documentoEjemplo.fechaEmision);
    const fechaVencimiento = formatDate(documentoEjemplo.fechaVencimiento);
    const valorUf = documentoEjemplo.valorUf || 0;

    // Logo
    const logoUrl = `${window.location.origin}/logoCBQ.png`;
    
    pdf.addImage(logoUrl, "PNG", marginLeft, marginTop, 30, 20);

    pdf.setDrawColor(0, 0, 0);

    // Recuadro Rut empresa
    pdf.rect(150, marginTop + 5, 50, 15);
    pdf.setFontSize(10);
    pdf.setFont("helvetica", "bold");
    pdf.text(`Rut: ${empresaRUT}`, 152, marginTop + 10);
    pdf.text(`N° Documento: ${numeroEstado}`, 152, marginTop + 17);

    // Título
    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text("Estado de Cuenta", 80, marginTop + 20);

    pdf.rect(marginLeft, marginTop + 30, 90, 7);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(`Srs: ${clienteNombre}`, marginLeft + 2, marginTop + 35);

    pdf.rect(marginLeft, marginTop + 40, 90, 7);
    pdf.text(`RUT: ${clienteRUT}`, marginLeft + 2, marginTop + 45);

    pdf.rect(marginLeft, marginTop + 50, 90, 7);
    pdf.text(`Periodo: ${mesAnio}`, marginLeft + 2, marginTop + 55);

    pdf.rect(marginLeft, marginTop + 60, 90, 7);
    pdf.text(
      `Valor UF: $${formatNumberUf(valorUf)}`,
      marginLeft + 2,
      marginTop + 65
    );

    // Información recuadro derecha
    pdf.rect(110, marginTop + 30, 90, 7);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(11);
    pdf.text(`Inicio Periodo: ${inicio}`, 112, marginTop + 35);

    pdf.rect(110, marginTop + 40, 90, 7);
    pdf.text(`Fin Periodo: ${fin}`, 112, marginTop + 45);

    pdf.rect(110, marginTop + 50, 90, 7);
    pdf.text(`Fecha Emision: ${fechaEmision}`, 112, marginTop + 55);

    pdf.rect(110, marginTop + 60, 90, 7);
    pdf.text(`Fecha Vencimiento: ${fechaVencimiento}`, 112, marginTop + 65);

    pdf.setFontSize(12);
    pdf.rect(marginLeft, marginTop + 78, 90, 45);
    pdf.text("Resumen de pagos", marginLeft, marginTop + 75);

    pdf.rect(110, marginTop + 78, 90, 45);
    pdf.text("Formas de pagos", 110, marginTop + 75);

    const calculateTotalCLP = (unitario, tipoDocumento) =>
      tipoDocumento.toLowerCase() === "factura afecta"
        ? unitario * 1.19
        : unitario;

    const arriendos = rowData.documentos.flatMap((doc) =>
      doc.detallesCobro
        .filter((detalle) => detalle.glosa.toLowerCase().includes("arriendo"))
        .map((detalle) => ({
          ...detalle,
          docCobro: doc.tipoDocumento,
          folio: doc.folio,
          totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
        }))
    );

    const servicios = rowData.documentos.flatMap((doc) =>
      doc.detallesCobro
        .filter((detalle) =>
          ["agua", "electricidad", "internet"].some((serv) =>
            detalle.glosa.toLowerCase().includes(serv)
          )
        )
        .map((detalle) => ({
          ...detalle,
          docCobro: doc.tipoDocumento,
          folio: doc.folio,
          totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
        }))
    );

    const gastoComun = rowData.documentos.flatMap((doc) =>
      doc.detallesCobro
        .filter((detalle) =>
          detalle.glosa.toLowerCase().includes("gasto comun")
        )
        .map((detalle) => ({
          ...detalle,
          docCobro: doc.tipoDocumento,
          folio: doc.folio,
          totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
        }))
    );

    const obrasCiviles = rowData.documentos.flatMap((doc) =>
      doc.detallesCobro
        .filter((detalle) =>
          ["obra civil", "habilitacion"].some((keyword) =>
            detalle.glosa.toLowerCase().includes(keyword)
          )
        )
        .map((detalle) => ({
          ...detalle,
          docCobro: doc.tipoDocumento,
          folio: doc.folio,
          totalCLP: calculateTotalCLP(detalle.unitario, doc.tipoDocumento),
        }))
    );

    // Calcular total de IVA para todas las categorías
    const totalIVAArriendos = arriendos.reduce(
      (acc, detalle) =>
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? acc + detalle.unitario * 0.19
          : acc,
      0
    );

    const totalIVAServicios = servicios.reduce(
      (acc, detalle) =>
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? acc + detalle.unitario * 0.19
          : acc,
      0
    );

    const totalIVAGastoComun = gastoComun.reduce(
      (acc, detalle) =>
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? acc + detalle.unitario * 0.19
          : acc,
      0
    );

    const totalIVAObrasCiviles = obrasCiviles.reduce(
      (acc, detalle) =>
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? acc + detalle.unitario * 0.19
          : acc,
      0
    );

    // Total general del IVA
    const totalIVA =
      totalIVAArriendos +
      totalIVAServicios +
      totalIVAGastoComun +
      totalIVAObrasCiviles;

    // Función para poblar la tabla en el PDF
    const populateTable = (columns, data, startY, title) => {
      pdf.text(title, marginLeft, startY - 2);
      pdf.autoTable({
        startY: startY,
        head: [columns],
        body: data.length ? data : [["NO APLICA"]],
        styles: { halign: "center", valign: "middle" },
        headStyles: { fillColor: [200, 200, 200] },
      });
      return pdf.autoTable.previous.finalY + 10; // Espacio para la siguiente tabla
    };

    const totalArriendos = arriendos.reduce(
      (acc, arriendo) => acc + arriendo.unitario * arriendo.cantidad,
      0
    );
    const totalServicios = servicios.reduce(
      (acc, serv) => acc + serv.unitario * serv.cantidad,
      0
    );
    const totalGastoComun = gastoComun.reduce(
      (acc, gasto) => acc + gasto.unitario * gasto.cantidad,
      0
    );
    const totalObrasCiviles = obrasCiviles.reduce(
      (acc, obra) => acc + obra.unitario * obra.cantidad,
      0
    );
    const totalPagar =
      totalArriendos + totalServicios + totalGastoComun + totalObrasCiviles;

    pdf.setFontSize(11);
    pdf.text("Item", marginLeft + 5, marginTop + 83);
    pdf.text(" Neto", marginLeft + 55, marginTop + 83);
    pdf.rect(marginLeft + 3, marginTop + 84, 85, 0.1);

    pdf.text("Saldo Anterior", marginLeft + 5, marginTop + 89);
    pdf.text("Arriendo(s)", marginLeft + 5, marginTop + 94);
    pdf.text("Gasto Común", marginLeft + 5, marginTop + 99);
    pdf.text("Servicios", marginLeft + 5, marginTop + 104);
    pdf.text("Obras Civiles", marginLeft + 5, marginTop + 109);
    pdf.setFontSize(12);
    pdf.text("TOTAL", marginLeft + 5, marginTop + 116);
    pdf.text("IVA", marginLeft + 5, marginTop + 121); // Agregar IVA al resumen

    pdf.setFontSize(11);
    pdf.text(
      `$ ${formatNumber(totalArriendos)}`,
      marginLeft + 55,
      marginTop + 94
    );
    pdf.text(
      `$ ${formatNumber(totalGastoComun)}`,
      marginLeft + 55,
      marginTop + 99
    );
    pdf.text(
      `$ ${formatNumber(totalServicios)}`,
      marginLeft + 55,
      marginTop + 104
    );
    pdf.text(
      `$ ${formatNumber(totalObrasCiviles)}`,
      marginLeft + 55,
      marginTop + 109
    );
    pdf.setFontSize(12);
    pdf.text(`$ ${formatNumber(totalPagar)}`, marginLeft + 55, marginTop + 116);
    pdf.text(`$ ${formatNumber(totalIVA)}`, marginLeft + 55, marginTop + 121);

    // Seleccionar información bancaria basada en rutEmpresa
    let bancoInfo = {};
    if (rowData.rutEmpresa === "76.071.007-5") {
      bancoInfo = {
        banco: "Banco ITAU",
        cuenta: "212099106",
        nombre: "ALTAMIRA S.A.",
        rut: "76.071.007-5",
        tipoCuenta: "Cuenta Corriente",
      };
    } else if (rowData.rutEmpresa === "76.066.122-8") {
      bancoInfo = {
        banco: "BANCO ITAU",
        cuenta: "223286902",
        nombre: "SOC AGRIC E INMOBILIARIA LA SIEMBRA LTDA",
        rut: "76.066.122-8",
        tipoCuenta: "Cuenta Corriente",
      };
    } else {
      bancoInfo = {
        banco: "No disponible",
        cuenta: "No disponible",
        nombre: "No disponible",
        rut: "No disponible",
        tipoCuenta: "No disponible",
      };
    }

    pdf.setFontSize(12);
    pdf.text("- Depósito", 112, marginTop + 83);
    pdf.text("- Transferencia Bancaria", 112, marginTop + 88);

    // Banco
    const bancoTexto = `Banco: ${bancoInfo.banco}`;
    const bancoDividido = pdf.splitTextToSize(bancoTexto, 85); // Ajustar ancho según espacio disponible
    pdf.text(bancoDividido, 112, marginTop + 95);

    // Calcular nueva posición en función de las líneas del texto anterior
    let currentY = marginTop + 95 + bancoDividido.length * 5;

    // Cuenta
    const cuentaTexto = `Cuenta: ${bancoInfo.cuenta}`;
    const cuentaDividido = pdf.splitTextToSize(cuentaTexto, 85);
    pdf.text(cuentaDividido, 112, currentY);
    currentY += cuentaDividido.length * 5;

    // Nombre
    const nombreTexto = `Nombre: ${bancoInfo.nombre}`;
    const nombreDividido = pdf.splitTextToSize(nombreTexto, 85);
    pdf.text(nombreDividido, 112, currentY);
    currentY += nombreDividido.length * 5;

    // RUT
    const rutTexto = `RUT: ${bancoInfo.rut}`;
    const rutDividido = pdf.splitTextToSize(rutTexto, 85);
    pdf.text(rutDividido, 112, currentY);
    currentY += rutDividido.length * 5;

    // Tipo de Cuenta
    const tipoCuentaTexto = `Tipo de Cuenta: ${bancoInfo.tipoCuenta}`;
    const tipoCuentaDividido = pdf.splitTextToSize(tipoCuentaTexto, 85);
    pdf.text(tipoCuentaDividido, 112, currentY);

    // Columnas y datos para la tabla de Arriendos
    const arriendoColumns = [
      "ITEM",
      "DOC. COBRO",
      "FOLIO",
      "NETO",
      "IVA",
      "TOTAL UF",
      "TOTAL CLP",
    ];
    const arriendoData = arriendos.map((detalle) => [
      detalle.glosa,
      detalle.docCobro,
      detalle.folio,
      formatNumber(detalle.unitario), // NETO
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? formatNumber(detalle.unitario * 0.19) // IVA 19%
        : "N/A", // No aplica IVA
      formatNumberUf(detalle.totalCLP / valorUf), // TOTAL UF
      formatNumber(detalle.totalCLP), // TOTAL CLP con IVA si aplica
    ]);

    // Columnas y datos para la tabla de Servicios
    const servicioColumns = [
      "Servicio",
      "Lectura Inicial",
      "Lectura Final",
      "Consumo",
      "Unidad",
      "DOC. COBRO",
      "FOLIO",
      "NETO",
      "IVA",
      "Total",
    ];
    const servicioData = servicios.map((detalle) => {
      const neto = detalle.unitario * detalle.cantidad; // Neto calculado como consumo por valor unitario
      const iva =
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? neto * 0.19 // IVA 19% si aplica
          : 0;
      const totalCLP = neto + iva; // Total CLP basado en el neto más el IVA si aplica

      return [
        detalle.glosa,
        detalle.lecturaInicial || "N/A",
        detalle.lecturaFinal || "N/A",
        detalle.cantidad,
        detalle.unidadMedida,
        detalle.docCobro,
        detalle.folio,
        formatNumber(neto), // Monto neto
        detalle.docCobro.toLowerCase() === "factura afecta"
          ? formatNumber(iva) // IVA 19%
          : "N/A",
        formatNumber(totalCLP), // Total CLP correcto
      ];
    });

    const gastoComunColumns = [
      "Item",
      "DOC. COBRO",
      "FOLIO",
      "NETO",
      "IVA",
      "Total",
    ];
    const gastoComunData = gastoComun.map((detalle) => [
      detalle.glosa,
      detalle.docCobro,
      detalle.folio,
      formatNumber(detalle.unitario), // NETO
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? formatNumber(detalle.unitario * 0.19) // IVA 19%
        : "N/A", // No aplica IVA
      formatNumber(detalle.totalCLP),
    ]);

    const obraCivilColumns = [
      "Item",
      "DOC. COBRO",
      "FOLIO",
      "NETO",
      "IVA",
      "Total",
    ];
    const obraCivilData = obrasCiviles.map((detalle) => [
      detalle.glosa,
      detalle.docCobro,
      detalle.folio,
      formatNumber(detalle.unitario), // NETO
      detalle.docCobro.toLowerCase() === "factura afecta"
        ? formatNumber(detalle.unitario * 0.19) // IVA 19%
        : "N/A", // No aplica IVA
      formatNumber(detalle.totalCLP),
    ]);

    // Agregar tablas al PDF
    currentY = marginTop + 135;

    currentY = populateTable(
      arriendoColumns,
      arriendoData,
      currentY,
      "Detalles Arriendos"
    );
    currentY = populateTable(
      servicioColumns,
      servicioData,
      currentY,
      "Detalles Servicios"
    );
    currentY = populateTable(
      gastoComunColumns,
      gastoComunData,
      currentY,
      "Detalles Gasto Común"
    );
    currentY = populateTable(
      obraCivilColumns,
      obraCivilData,
      currentY,
      "Detalles Obras Civiles e Instalaciones"
    );

    // Posición inicial para las notas
    let finalY = pdf.lastAutoTable ? pdf.lastAutoTable.finalY + 10 : 270;

    // Espacio disponible en la página actual
    const pageHeight = pdf.internal.pageSize.height;
    const marginBottom = 20; // Margen inferior para evitar que el contenido toque el borde

    // Verifica si hay espacio suficiente para las notas
    if (finalY + 40 > pageHeight - marginBottom) {
      pdf.addPage(); // Añade una nueva página si el contenido excede el espacio disponible
      finalY = 20; // Reinicia la posición Y al inicio de la nueva página
    }

    // Renderiza el recuadro de las notas
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(10, finalY, 190, 30); // Dibuja un rectángulo para "NOTAS" con ancho 190 y alto 30

    // Agrega el contenido de las notas
    pdf.setFontSize(10);
    pdf.setFont("helvetica", "normal");
    pdf.text("NOTAS", 12, finalY + 6);

    const notas = [
      "1.- Los documentos tributarios se harán llegar a usted en forma separada a este resumen.",
      "2.- El valor de la UF corresponde al primer día hábil del mes de emisión de este Estado de Cuenta.",
      "3.- La fecha límite para su pago es el 5to día hábil del mes.",
      "4.- Estamos atentos a atenderlos a través del teléfono XXXXXXXXXX o mail XXXXX@XXXXX.cl",
    ];

    let offsetY = finalY + 12; // Posición inicial del texto de notas

    notas.forEach((line) => {
      pdf.text(line, 12, offsetY); // Posición de cada línea en X=12
      offsetY += 5; // Espacio entre líneas
    });

    // Abrir el PDF en una nueva ventana
    const pdfData = pdf.output("bloburl");
    window.open(pdfData);
  };

  const [globalFilter, setGlobalFilter] = useState("");

  const calcularTotalIVA = (total, tipoDocumento) =>
    tipoDocumento.toLowerCase() === "factura afecta" ? total * 1.19 : total;

  // Template for Factura Afecta column
  const facturaAfectaTemplate = (rowData) => {
    const facturaAfecta = rowData.documentos.find(
      (doc) => doc.tipoDocumento.toLowerCase() === "factura afecta"
    );

    if (facturaAfecta) {
      const totalConIVA = calcularTotalIVA(
        facturaAfecta.total,
        facturaAfecta.tipoDocumento
      );

      return (
        <span>
          {totalConIVA.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}
        </span>
      );
    }
    return "-";
  };

  // Template for Factura Exenta column
  const facturaExentaTemplate = (rowData) => {
    const facturaExenta = rowData.documentos.find(
      (doc) => doc.tipoDocumento === "factura exenta"
    );
    if (facturaExenta) {
      return (
        <span>
          {facturaExenta.total.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}
        </span>
      );
    }
    return "-";
  };

  // Template for Nota de Venta column
  const notaVentaTemplate = (rowData) => {
    const notaVenta = rowData.documentos.find(
      (doc) => doc.tipoDocumento === "nota de cobro"
    );
    if (notaVenta) {
      return (
        <span>
          {notaVenta.total.toLocaleString("es-CL", {
            style: "currency",
            currency: "CLP",
          })}
        </span>
      );
    }
    return "-";
  };

  return (
    <div>
      <h3>Estados de cuenta</h3>
      <small>Aqui podrá revisar el estado de cuenta mensual por contrato</small>
      <div
        className="flex flex-column align-items-center justify-content-center mb-5 bg-surface-50"
        style={{ marginTop: "20px" }}
      >
        <div className="card shadow-4 surface-card border-round-md w-full max-w-lg">
          <h4 className="text-center text-primary mb-4">
            Seleccione la información deseada
          </h4>

          <div className="grid formgrid">
            <div className="field col-12 md:col-4 mb-4">
              <label
                htmlFor="empresa"
                className="block font-semibold mb-1 text-gray-600"
              >
                Empresa
              </label>
              <Dropdown
                inputId="empresa"
                aria-label="Seleccionar empresa"
                value={empresa}
                options={empresas.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setEmpresa(e.value)}
                optionLabel="label"
                optionValue="id"
                placeholder="Seleccione Empresa"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="field col-12 md:col-4 mb-4">
              <label
                htmlFor="anio"
                className="block font-semibold mb-1 text-gray-600"
              >
                Año
              </label>
              <Dropdown
                inputId="anio"
                aria-label="Seleccionar año"
                value={anio}
                options={anios.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setAnio(e.value)}
                placeholder="Seleccione Año"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="field col-12 md:col-4 mb-4">
              <label
                htmlFor="mes"
                className="block font-semibold mb-1 text-gray-600"
              >
                Mes
              </label>
              <Dropdown
                inputId="mes"
                aria-label="Seleccionar mes"
                value={mes}
                options={meses.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setMes(e.value)}
                optionLabel="label"
                optionValue="mes"
                placeholder="Seleccione Mes"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>
          </div>

          <div className="text-center mt-4">
            <Button
              label="Buscar"
              aria-label="Buscar"
              icon="pi pi-search"
              onClick={handleBuscar}
              className="p-button-raised p-button-primary w-full md:w-auto px-6 py-2 text-lg font-medium shadow-lg transition duration-150 ease-in-out"
            />
          </div>
        </div>
      </div>
      {show1 && (
        <>
          <style>
            {`
        /* Wrapper for rounded table shape */
        .table-wrapper {
          border-radius: 0.5rem;
          overflow: hidden;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
        }

        /* Table Styling */
        .p-datatable {
          border: none;
          border-radius: 0.5rem;
        }
        .p-datatable .p-datatable-thead > tr > th {
          background-color: #e3f2fd; /* Light blue header */
          font-weight: 600;
          text-align: center;
          border-bottom: none;
        }

        .p-datatable .p-datatable-tbody > tr {
          transition: background-color 0.2s ease;
        }

        /* Row hover effect */
        .p-datatable .p-datatable-tbody > tr:hover {
          background-color: #f1f5f9;
        }

        /* Alternate row colors */
        .p-datatable .p-datatable-tbody > tr:nth-child(odd) {
          background-color: #ffffff;
        }
        .p-datatable .p-datatable-tbody > tr:nth-child(even) {
          background-color: #f9fafb;
        }

        /* Centering text */
        .p-datatable .p-column-header-content {
          justify-content: center;
        }

        /* Table cell padding and rounding */
        .p-datatable .p-datatable-tbody > tr > td {
          padding: 1rem;
          border-radius: 0.25rem;
        }

        /* Action Column Icons */
        .action-icons {
          display: flex;
          gap: 0.5rem;
        }

        /* Icon Styling */
        .icon {
          font-size: 1.5rem;
          color: #007bff;
          cursor: pointer;
          transition: color 0.2s ease;
        }
        .icon:hover {
          color: #0056b3; /* Darker blue on hover */
        }

        /* Responsive */
        .table-wrapper {
          overflow-x: auto;
        }

        /* Custom loading spinner */
        .custom-spinner {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          font-size: 2rem;
          color: #007bff;
        }
      `}
          </style>
          <div className="inputgroup">
            <InputText
              placeholder="Buscar por Cliente"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              style={{
                borderRadius: "0.5rem",
                padding: "0.75rem 1rem",
                boxShadow: "0 2px 8px rgba(0, 123, 255, 0.2)",
                fontSize: "1rem",
              }}
            />
          </div>{" "}
          <DataTable
            className="p-datatable-gridlines"
            value={documentosData}
            style={{ marginTop: "20px" }}
            loading={isLoading}
            rows={40}
            paginator
            emptyMessage="No se encontraron estados de cuenta"
            scrollable
            scrollDirection="both"
            globalFilter={globalFilter}
          >
            <Column field="nombreCliente" header="Cliente" sortable />
            <Column field="descripcionGrupo" header="Asignación" sortable />
            <Column field="rutCliente" header="Rut Cliente" sortable />
            <Column field="inicioPago" header="Inicio del Pago" sortable />
            <Column field="finPago" header="Fin del Pago" sortable />
            <Column
              header="Factura Afecta"
              body={facturaAfectaTemplate}
              sortable
            />
            <Column
              header="Factura Exenta"
              body={facturaExentaTemplate}
              sortable
            />
            <Column header="Nota de Venta" body={notaVentaTemplate} sortable />
            <Column header="Acciones" body={actionTemplate} sortable />
          </DataTable>
        </>
      )}
      <Dialog
        header="Documentos asociados"
        visible={visibleDialog}
        style={{ width: "80vw" }}
        modal
        onHide={() => setVisibleDialog(false)}
      >
        {selectedDocument && (
          <DataTable
            className="p-datatable-gridlines"
            value={selectedDocument}
            expandedRows={expandedRows}
            onRowToggle={(e) => setExpandedRows(e.data)}
            rowExpansionTemplate={rowExpansionTemplate}
            dataKey="idDocumento"
            emptyMessage="No se encontraron detalles para este estado de cuenta"
          >
            <Column
              field="idDocumento"
              header="N° Documento"
              style={{ textAlign: "center" }}
            />
            <Column
              field="tipoDocumento"
              header="Tipo Documento"
              style={{ textAlign: "center" }}
            />
            <Column
              field="total"
              header="Total"
              body={(rowData) => formatNumber(rowData.total)}
              style={{ textAlign: "center" }}
            />
            <Column
              header="Ver Detalle"
              body={expandButtonTemplate}
              style={{ textAlign: "center", width: "3em" }}
            />
          </DataTable>
        )}
      </Dialog>
    </div>
  );
};

export default Estados;
