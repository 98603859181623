import React, { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { useGlobalContext } from "../Config";
import { jsPDF } from "jspdf";
import { Dialog } from "primereact/dialog";
import "jspdf-autotable";
import moment from "moment-timezone";
import FileUploadNew from "../components/FileUploadNew";

const Documentos = (props) => {
  const { baseUrl } = useGlobalContext();
  const { headerText } = useGlobalContext();
  const [loading, setLoading] = useState(true);
  const { styleProperties } = useGlobalContext();

  const [empresa, setEmpresa] = useState(null);
  const [empresas, setEmpresas] = useState([]);
  const [anio, setAnio] = useState(moment().year());
  const [mes, setMes] = useState(moment().format("MM"));
  const [documento, setDocumento] = useState(null);
  const [documentos, setDocumentos] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [showTable, setShowTable] = useState(false);
  const [documentosData, setDocumentosData] = useState([]);
  const userId = sessionStorage.getItem("userId");
  const customHeaderStyle = { backgroundColor: "#e0dddc" };
  const [encodedFile, setEncodedFile] = useState(null);
  const [totalEmitidos, setTotalEmitidos] = useState(0);
  const [documentosRegistrados, setDocumentosRegistrados] = useState(0);
  const [documentosPorRegistrar, setDocumentosPorRegistrar] = useState(0);
  const [isUploading, setIsUploading] = useState(false);
  const [folioTemp, setFolioTemp] = useState({ id: null, folio: null });
  const [detalleVisible, setDetalleVisible] = useState(false);
  const [detalleCobro, setDetalleCobro] = useState([]);
  const [uf, setUf] = useState(0);
  const [estadoDocumento, setEstadoDocumento] = useState("todos");

  const currentYear = new Date().getFullYear();
  const anios = Array.from({ length: currentYear - 2020 + 5 }, (_, i) => ({
    label: String(2020 + i),
    value: 2020 + i,
  }));
  const meses = Array.from({ length: 12 }, (_, i) => {
    const date = new Date(0, i); // Set date to month `i`
    return {
      label:
        date.toLocaleString("es", { month: "long" }).charAt(0).toUpperCase() +
        date.toLocaleString("es", { month: "long" }).slice(1),
      mes: String(i + 1).padStart(2, "0"), // Month number with leading zero
    };
  });

  useEffect(() => {
    const fetchEmpresas = async () => {
      const endpoint = `${baseUrl}getEmpresasByUsuario?idUsuario=${userId}`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(data.mensaje || "Error al obtener las empresas.");
        }

        // Mapea las empresas para que tengan label y value
        const empresasFetched = data.empresas.map((empresa) => ({
          label: empresa.nombre, // Nombre de la empresa como label
          id: empresa.id, // ID de la empresa como value
        }));
        setEmpresas(empresasFetched);
        setEmpresa(empresasFetched[0].id);
      } catch (error) {
        console.error("Error fetching empresas:", error);
      }
    };

    fetchEmpresas();
  }, [baseUrl, userId]);

  useEffect(() => {
    const fetchTiposDocumento = async () => {
      const endpoint = `${baseUrl}getTiposDocumento`;

      try {
        const response = await fetch(endpoint, {
          method: "GET",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(
            data.mensaje || "Error al obtener tipos de documento."
          );
        }

        const tiposDocumentoFetched = [
          { label: "Ver todo", codigo: 0 },
          ...data.tiposDocumento.map((tipo) => ({
            label: tipo.descripcion,
            codigo: tipo.codigo,
          })),
        ];

        setDocumentos(tiposDocumentoFetched);
        setDocumento(tiposDocumentoFetched[0].codigo);
      } catch (error) {
        console.error("Error fetching tipos de documento:", error);
      }
    };

    fetchTiposDocumento();
  }, [baseUrl]);

  const [importeTotal, setImporteTotal] = useState(0);
  const [documentosEncontrados, setDocumentosEncontrados] = useState(0);
  const resetDocumentData = () => {
    setImporteTotal(0); // Assuming the initial total amount is 0
    setTotalEmitidos(0); // Initial count of issued documents
    setDocumentosRegistrados(0); // Initial count of registered documents
    setDocumentosPorRegistrar(0); // Initial count of documents to be registered
    setDocumentosEncontrados(0); // Initial count of found documents
    setDocumentosData([]); // Assuming documents data is an empty array initially
  };
  const buscarDocumentos = async () => {
    if (
      !empresa ||
      !anio ||
      !mes ||
      documento === null ||
      documento === undefined
    ) {
      alert("Por favor, seleccione todos los campos antes de buscar.");
      return;
    }

    try {
      setIsLoading(true);
      setShowTable(true);

      const endpoint = `${baseUrl}obtenerDocumentos?idEmpresa=${empresa}&anio=${anio}&mes=${mes}&tipoDocumento=${documento}`;

      const response = await fetch(endpoint, {
        method: "GET",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();

      if (!response.ok || data.respuesta !== true) {
        alert(data.mensaje || "Error al obtener los documentos.");
        resetDocumentData();
        return;
      }

      if (
        !data.documentos ||
        !Array.isArray(data.documentos) ||
        data.documentos.length === 0
      ) {
        alert("No se encontraron documentos para los parámetros ingresados.");
        resetDocumentData();
        return;
      }

      const documentosFetched = data.documentos.map((doc) => {
        const totalIva =
          doc.tipoDocumento.toLowerCase() === "factura afecta"
            ? Math.floor(doc.total * 0.19)
            : 0;

        const fechaDocumento =
          doc.adjuntos && doc.adjuntos.length > 0
            ? doc.adjuntos[0].fecha
            : "Documento no disponible";

        const detallesCobro = doc.detallesCobro.map((detalle) => ({
          codigo: detalle.codigo,
          unidadMedida: detalle.unidadMedida,
          glosa: detalle.glosa,
          cantidad: detalle.cantidad,
          unitario: detalle.unitario,
          lecturaInicial: detalle.lecturaInicial || "No aplica", // Lectura inicial
          lecturaFinal: detalle.lecturaFinal || "No aplica", // Lectura final
          idCobro: detalle.idCobro,
        }));

        return {
          id_documento: doc.idDocumento,
          empresa: doc.descripcionEmpresa,
          rut_empresa: doc.rutEmpresa,
          cliente: doc.nombreCliente,
          rut_cliente: doc.rutCliente,
          inicio_pago: doc.inicioPago,
          fin_Pago: doc.finPago,
          documento: doc.tipoDocumento,
          n_documento: doc.folio,
          fecha_documento: fechaDocumento,
          total_neto: doc.total,
          total_iva: totalIva,
          total_documento: doc.total + totalIva,
          descripcion_grupo: doc.descripcionGrupo,
          adjuntos: doc.adjuntos || [],
          detalleCobro: detallesCobro,
          fecha_emision: doc.fechaEmision, // Nueva propiedad fecha de emisión
          fecha_vencimiento: doc.fechaVencimiento, // Nueva propiedad fecha de vencimiento
          valor_uf: doc.valorUf,
        };
      });

      // Calcular el valor más alto de valor_uf
      const highestUf = documentosFetched.reduce(
        (max, doc) => (doc.valor_uf > max ? doc.valor_uf : max),
        0
      );

      // Asignar el valor más alto de valor_uf a la variable uf
      setUf(highestUf);

      const totalImporte = documentosFetched.reduce(
        (sum, doc) => sum + doc.total_documento,
        0
      );

      const totalEmitidos = documentosFetched
        .filter((doc) => doc.adjuntos && doc.adjuntos.length > 0)
        .reduce((sum, doc) => sum + doc.total_documento, 0);

      // Contar documentos con y sin adjuntos
      const countRegistrados = documentosFetched.filter(
        (doc) => doc.adjuntos && doc.adjuntos.length > 0
      ).length;
      const countPorRegistrar = documentosFetched.filter(
        (doc) => !doc.adjuntos || doc.adjuntos.length === 0
      ).length;

      setImporteTotal(totalImporte);
      setTotalEmitidos(totalEmitidos);
      setDocumentosRegistrados(countRegistrados);
      setDocumentosPorRegistrar(countPorRegistrar);
      setDocumentosEncontrados(documentosFetched.length);
      setDocumentosData(documentosFetched);
    } catch (error) {
      console.error("Error fetching documentos:", error);
      alert("Error: " + error.message);
      resetDocumentData();
    } finally {
      setIsLoading(false);
    }
  };

  const onEditorValueChange = (props, value) => {
    const numericValue = value.replace(/\D/g, ""); // Si quieres solo números

    let updatedData = [...documentosData];

    const rowIndex = documentosData.findIndex(
      (doc) => doc.id_documento === props.rowData.id_documento
    );

    if (updatedData[rowIndex]) {
      updatedData[rowIndex][props.field] = numericValue; // Actualiza el valor en la fila correspondiente
      setDocumentosData(updatedData); // Actualiza el estado con la nueva lista de documentos
      setFolioTemp({ id: props.rowData.id_documento, folio: numericValue }); // Almacena temporalmente el folio
    }
  };

  const actualizarFolioDocumento = async (rowData) => {
    if (folioTemp.id && folioTemp.folio !== null) {
      if (rowData.documento.toLowerCase() === "nota de cobro") return;
      const isConfirmed = window.confirm(
        `¿Es correcto el número ingresado (${folioTemp.folio})?`
      );

      if (!isConfirmed) {
        //alert("Actualización cancelada.");
        return;
      }
      try {
        const endpoint = `${baseUrl}actualizarFolio?id_documento=${folioTemp.id}&folio=${folioTemp.folio}`;
        const response = await fetch(endpoint, {
          method: "POST",
          credentials: "include",
          redirect: "follow",
        });

        const data = await response.json();

        if (!response.ok || !data.respuesta) {
          throw new Error(data.mensaje || "Error al actualizar el folio.");
        }
        alert("Folio actualizado correctamente");

        // Llamar al servicio de cobranza
        const cobranzaPayload = {
          cliente: rowData.rut_cliente, // RUT del cliente
          deuda: rowData.total_documento, // Total del documento
          fechaEmision: rowData.fecha_emision, // Fecha de emisión
          fechaVencimiento: rowData.fecha_vencimiento, // Fecha de vencimiento
          folio: rowData.n_documento, // Folio actualizado
          neto: rowData.total_neto, // Total neto
          tipoDocumento:
            rowData.documento.toLowerCase() === "factura afecta" ? 1 : 2, // Tipo de documento (1 para afecta, 2 para exenta)
        };

        const cobranzaEndpoint = `${baseUrl}cobranzaService/agregarDocumento`;
        const cobranzaResponse = await fetch(cobranzaEndpoint, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          credentials: "include",
          redirect: "follow",
          body: JSON.stringify(cobranzaPayload),
        });

        const cobranzaData = await cobranzaResponse.json();

        if (!cobranzaResponse.ok || !cobranzaData.respuesta) {
          throw new Error(
            cobranzaData.mensaje || "Error al procesar cobranza."
          );
        }

        alert("Documento enviado al servicio de cobranza correctamente.");

        buscarDocumentos();
      } catch (error) {
        console.error("Error actualizando el folio:", error);
        alert("Error: " + error.message);
      }
    }
  };

  const confirmarYActualizar = async (rowData) => {
    if (rowData.documento.toLowerCase() === "nota de cobro") return; // No aplicar lógica para Notas de Cobro

    const isConfirmed = window.confirm(
      `¿Es correcto el número ingresado (${folioTemp.folio})?`
    );

    if (!isConfirmed) {
      // Si el usuario no confirma, no hacemos nada
      alert("Actualización cancelada.");
      return;
    }

    try {
      // Actualizar folio en el sistema principal
      const endpoint = `${baseUrl}actualizarFolio?id_documento=${folioTemp.id}&folio=${folioTemp.folio}`;
      const response = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
      });

      const data = await response.json();

      if (!response.ok || !data.respuesta) {
        throw new Error(data.mensaje || "Error al actualizar el folio.");
      }

      alert("Folio actualizado correctamente en el sistema principal.");

      // Llamar al servicio de cobranza
      const cobranzaPayload = {
        cliente: rowData.rut_cliente, // RUT del cliente
        deuda: rowData.total_documento, // Total del documento
        fechaEmision: rowData.fecha_emision, // Fecha de emisión
        fechaVencimiento: rowData.fecha_vencimiento, // Fecha de vencimiento
        folio: rowData.n_documento, // Folio actualizado
        neto: rowData.total_neto, // Total neto
        tipoDocumento:
          rowData.documento.toLowerCase() === "factura afecta" ? 1 : 2, // Tipo de documento (1 para afecta, 2 para exenta)
      };

      const cobranzaEndpoint = `${baseUrl}cobranzaService/agregarDocumento`;
      const cobranzaResponse = await fetch(cobranzaEndpoint, {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        credentials: "include",
        redirect: "follow",
        body: JSON.stringify(cobranzaPayload),
      });

      const cobranzaData = await cobranzaResponse.json();

      if (!cobranzaResponse.ok || !cobranzaData.respuesta) {
        throw new Error(cobranzaData.mensaje || "Error al procesar cobranza.");
      }

      alert("Documento enviado al servicio de cobranza correctamente.");
    } catch (error) {
      console.error("Error al actualizar el folio o procesar cobranza:", error);
      alert("Error: " + error.message);
    }
  };

  /////////////////////////////////////////////// LOGICA PARA GUARDAR LOS DOCUMENTOS SUBIDOS A LA APP. ///////////////////////////////////////////////

  // const documentoSubidoTemplate = (rowData) => {
  //   const tieneAdjunto = rowData.adjuntos && rowData.adjuntos.length > 0;
  //   const tipoDocumento = rowData.documento.toLowerCase();
  //   const uploadButtonRef = useRef(null);
  //   const downloadButtonRef = useRef(null);
  //   const pdfButtonRef = useRef(null);

  //   if (tipoDocumento === "factura afecta" || tipoDocumento === "factura exenta") {
  //     if (!tieneAdjunto) {
  //       return (
  //         <>
  //           <FileUpload
  //             mode="basic"
  //             chooseOptions={{
  //               className:
  //                 "p-button-rounded p-button-primary p-button-icon-only p-button-lg custom-file-upload",
  //               icon: "pi pi-upload",
  //               label: 'Upload Document',
  //             }}
  //             multiple={false}
  //             customUpload
  //             uploadHandler={(e) => handleFileUpload(e, rowData)}
  //             disabled={isUploading}
  //             ref={uploadButtonRef}
  //           />
  //           <Tooltip
  //             target={uploadButtonRef.current?.element}
  //             content="Click to upload the document, then click again to confirm."
  //           />
  //         </>
  //       );
  //     } else {
  //       const adjunto = rowData.adjuntos[0];
  //       return (
  //         <>
  //           <Button
  //             icon="pi pi-download"
  //             className="p-button-rounded p-button-success"
  //             onClick={() => bajarArchivo(rowData.id_documento, adjunto.nombre)}
  //             disabled={isUploading}
  //             ref={downloadButtonRef}
  //           />
  //           <Tooltip
  //             target={downloadButtonRef.current?.element}
  //             content="Click to download the attached document."
  //           />
  //         </>
  //       );
  //     }
  //   } else if (tipoDocumento === "nota de cobro") {
  //     return (
  //       <>
  //         <Button
  //           icon="pi pi-file-pdf"
  //           className="p-button-rounded p-button-warning"
  //           onClick={() => exportToPDF(rowData)}
  //           disabled={isUploading}
  //           ref={pdfButtonRef}
  //         />
  //         <Tooltip
  //           target={pdfButtonRef.current?.element}
  //           content="Click to generate a PDF for this document."
  //         />
  //       </>
  //     );
  //   }

  //   return null;
  // };

  const detalleCobroTemplate = (rowData) => {
    console.log(rowData);
    return (
      <Button
        icon="pi pi-exclamation-circle"
        className="p-rounded-icon-only p-button-rounded p-button-info"
        onClick={() => verDetalle(rowData.detalleCobro)}
        style={{ marginRight: "3px" }}
      />
    );
  };

  const verDetalle = (rowData) => {
    setDetalleCobro(rowData);
    setDetalleVisible(true);
  };

  const handleFileUpload = async (event, rowData) => {
    if (!isSaved) {
    }
    const file = event.files[0];

    if (file) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const base64Data = btoa(e.target.result);

        const contentType = file.type;
        const fileName = file.name;
        const fileSize = file.size;

        setEncodedFile(base64Data);
        subirArchivo(
          JSON.stringify({
            contentType: contentType,
            fileName: fileName,
            base64: base64Data,
            fileSize: fileSize,
            idDocumento: rowData.id_documento,
          })
        );
      };

      reader.readAsBinaryString(file);
    }
  };

  const subirArchivo = async (archivo) => {
    const endpoint = baseUrl + "adjuntarArchivoDocumento";
    try {
      setIsUploading(true);
      const data = await fetch(endpoint, {
        method: "POST",
        credentials: "include",
        redirect: "follow",
        body: archivo,
        headers: { "Content-Type": "application/json" },
      }).then((res) => res.json());

      if (data.mensaje !== "OK") {
        throw new Error("Failed to create the request");
      }

      if (data.respuesta) {
        alert("Archivo subido exitosamente");

        setFolioTemp({ id: null, folio: null });
        await buscarDocumentos();
      } else {
        alert("Hubo un problema al subir el archivo");
      }
    } catch (error) {
      console.error("Error:", error.message);
    } finally {
      setIsUploading(false);
    }
  };

  const bajarArchivo = async (idDocumento, nombreArchivo) => {
    setLoading(true);
    try {
      const response = await fetch(
        baseUrl + "descargar/adjunto/documento?id=" + idDocumento,
        {
          method: "GET",
          credentials: "include",
          redirect: "follow",
          headers: { "Content-Type": "application/json" },
        }
      );

      if (!response.ok) {
        throw new Error(`Failed to download file. Status: ${response.status}`);
      }
      const blob = await response.blob(); // Get the response body as Blob

      const url = window.URL.createObjectURL(blob);

      const a = document.createElement("a");

      const fileName =
        response.headers.get("Content-Disposition")?.split("=")[1] ||
        nombreArchivo;

      a.href = url;
      a.download = fileName;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
    setLoading(false);
  };

  ////////////////////////////////// Creacion de PDF para las notas de cobro //////////////////////////////////

  async function loadLogo() {
    const logoUrlRemote = `${window.location.origin}/logoCBQ.png`;
    const logoUrlLocal = "/logoCBQ.png"; // Asegúrate de que está en /public

    async function fetchImage(url) {
      try {
        const response = await fetch(url);
        if (!response.ok) throw new Error("Error al cargar la imagen");
        const blob = await response.blob();
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.onerror = reject;
          reader.readAsDataURL(blob);
        });
      } catch (error) {
        console.warn(`No se pudo cargar la imagen desde ${url}:`, error);
        throw error;
      }
    }

    try {
      return await fetchImage(logoUrlRemote);
    } catch {
      return await fetchImage(logoUrlLocal);
    }
  }

  const exportToPDF = (documento) => {
    const pdf = new jsPDF("p", "mm", "a4");

    const marginLeft = 10;
    const marginTop = 10;
    let currentY = marginTop + 20; // Mantendremos el Y actual para ubicar las tablas dinámicamente

    // Datos generales del documento
    const clienteNombre = documento.cliente;
    const clienteRUT = documento.rut_cliente;
    const folioDocumento = documento.n_documento;

    // Fecha de emisión y fecha de vencimiento
    const fechaInicio = formatDate(documento.inicio_pago) || "No disponible";
    const fechaFin = formatDate(documento.fin_Pago) || "No disponible";

    const fechaEmision = formatDate(documento.fecha_emision) || "No disponible";
    const fechaVencimiento =
      formatDate(documento.fecha_vencimiento) || "No disponible";
    const valorUf = documento.valor_uf || 0;

    // Lógica para calcular las sumas de cada categoría
    const montosCobro = Array.isArray(documento.detalleCobro)
      ? documento.detalleCobro
      : [];

    let totalGastoComun = 0;
    let totalArriendos = 0;
    let totalObrasCiviles = 0;
    let totalServicios = 0;

    montosCobro.forEach((detalle) => {
      const descripcion = detalle.glosa.toLowerCase();
      const total = detalle.unitario * detalle.cantidad;

      if (descripcion.includes("gasto comun")) {
        totalGastoComun += total;
      } else if (descripcion.includes("arriendo")) {
        totalArriendos += total;
      } else if (descripcion.includes("obra civil")) {
        totalObrasCiviles += total;
      } else if (
        ["agua", "electricidad", "internet"].some((servicio) =>
          descripcion.includes(servicio)
        )
      ) {
        totalServicios += total;
      }
    });

    // Información bancaria según rutEmpresa
    let bancoInfo = {};
    if (documento.rut_empresa === "76.071.007-5") {
      bancoInfo = {
        banco: "Banco ITAU",
        cuenta: "212099106",
        nombre: "ALTAMIRA S.A.",
        rut: "76.071.007-5",
        tipoCuenta: "Cuenta Corriente",
      };
    } else if (documento.rut_empresa === "76.066.122-8") {
      bancoInfo = {
        banco: "BANCO ITAU",
        cuenta: "223286902",
        nombre: "SOC AGRIC E INMOBILIARIA LA SIEMBRA LTDA",
        rut: "76.066.122-8",
        tipoCuenta: "Cuenta Corriente",
      };
    } else {
      bancoInfo = {
        banco: "No disponible",
        cuenta: "No disponible",
        nombre: "No disponible",
        rut: "No disponible",
        tipoCuenta: "No disponible",
      };
    }

    // Logo (si tienes un logo, ajusta la ruta aquí)
    //const logoUrl = `${window.location.origin}/logoCBQ.png`;
    //pdf.addImage(logoUrl, "PNG", marginLeft, marginTop, 30, 20);

    try {
      const logoBase64 = loadLogo();
      pdf.addImage(logoBase64, "PNG", 10, 10, 30, 20);
    } catch (error) {
      console.error("No se pudo cargar el logo:", error);
    }

    pdf.setDrawColor(0, 0, 0);
    pdf.rect(marginLeft + 140, marginTop + 8, 50, 12);

    pdf.setFontSize(16);
    pdf.setFont("helvetica", "bold");
    pdf.text("Nota de cobro", marginLeft + 145, marginTop + 7);
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "normal");
    pdf.text(`N°: ${folioDocumento}`, marginLeft + 145, marginTop + 15);

    // Información del cliente y empresa
    pdf.setDrawColor(0, 100, 100);
    pdf.rect(marginLeft, marginTop + 25, 93, 7);
    pdf.rect(marginLeft, marginTop + 33, 93, 7);
    pdf.rect(marginLeft, marginTop + 41, 93, 7);
    pdf.rect(marginLeft, marginTop + 49, 93, 7);

    pdf.rect(marginLeft + 97, marginTop + 25, 93, 7);
    pdf.rect(marginLeft + 97, marginTop + 33, 93, 7);
    pdf.rect(marginLeft + 97, marginTop + 41, 93, 7);
    pdf.rect(marginLeft + 97, marginTop + 49, 93, 7);

    pdf.setFontSize(12);
    pdf.setFont("times", "normal");
    pdf.text(`Srs: ${clienteNombre}`, marginLeft + 3, currentY + 10);
    pdf.text(`RUT: ${clienteRUT}`, marginLeft + 3, currentY + 18);
    pdf.text(
      `Periodo de Cobro: ${mes + "-" + anio}`,
      marginLeft + 3,
      currentY + 26
    );
    pdf.text(
      `Valor UF: $${formatNumberUf(valorUf)} `,
      marginLeft + 3,
      currentY + 34
    );

    pdf.text(`Inicio Periodo: ${fechaInicio}`, 110, currentY + 10);
    pdf.text(`Fin Periodo: ${fechaFin}`, 110, currentY + 18);
    pdf.text(`Fecha Emisión: ${fechaEmision}`, 110, currentY + 26);
    pdf.text(`Fecha Vencimiento: ${fechaVencimiento}`, 110, currentY + 34);

    currentY += 30;

    // Resumen de pagos
    pdf.setDrawColor(0, 100, 100);
    pdf.setFontSize(14);
    pdf.setFont("times", "normal");
    pdf.text("Resumen de Pagos", marginLeft + 27, currentY + 15);
    pdf.rect(marginLeft, currentY + 17, 93, 45);
    pdf.text("Formas de Pago", marginLeft + 127, currentY + 15);
    pdf.rect(marginLeft + 97, currentY + 17, 93, 45);

    pdf.text("Item", marginLeft + 5, currentY + 22);
    pdf.text("Monto", marginLeft + 70, currentY + 22);
    pdf.rect(marginLeft + 3, currentY + 24, 85, 0.1);

    pdf.setFontSize(11);
    pdf.text("Saldo Anterior", marginLeft + 5, currentY + 28);
    pdf.text("Arriendo(s)", marginLeft + 5, currentY + 33);
    pdf.text("Gasto Común", marginLeft + 5, currentY + 38);
    pdf.text("Servicios", marginLeft + 5, currentY + 43);
    pdf.text("Obras Civiles", marginLeft + 5, currentY + 48);
    pdf.setFontSize(12);
    pdf.text("TOTAL", marginLeft + 5, currentY + 58);

    pdf.text("$ ", marginLeft + 62, currentY + 28);
    pdf.text(
      `$ ${formatNumber(totalArriendos).toLocaleString()}`,
      marginLeft + 62,
      currentY + 33
    );
    pdf.text(
      `$ ${formatNumber(totalGastoComun).toLocaleString()}`,
      marginLeft + 62,
      currentY + 38
    );
    pdf.text(
      `$ ${formatNumber(totalServicios).toLocaleString()}`,
      marginLeft + 62,
      currentY + 43
    );
    pdf.text(
      `$ ${formatNumber(totalObrasCiviles).toLocaleString()}`,
      marginLeft + 62,
      currentY + 48
    );
    pdf.text(
      `$ ${formatNumber(
        totalGastoComun + totalArriendos + totalObrasCiviles + totalServicios
      ).toLocaleString()}`,
      marginLeft + 62,
      currentY + 58
    );

    pdf.setFontSize(12);
    pdf.text("- Depósito", marginLeft + 100, currentY + 22);
    pdf.text("- Transferencia Bancaria", marginLeft + 100, currentY + 27);

    // Calcular el texto para el campo Nombre
    const nombreTexto = `Nombre: ${bancoInfo.nombre}`;
    const nombreDividido = pdf.splitTextToSize(nombreTexto, 85); // Ajusta el ancho máximo aquí (85 en este caso)
    pdf.text(nombreDividido, marginLeft + 102, currentY + 33);

    // Calcular la nueva posición en función de las líneas generadas
    let newY = currentY + 33 + nombreDividido.length * 5; // Ajustar según el tamaño de línea (5 es un ejemplo)

    // Renderizar el resto de los campos dinámicamente
    pdf.text(`Banco: ${bancoInfo.banco}`, marginLeft + 102, newY);
    newY += 5;
    pdf.text(`Rut: ${bancoInfo.rut}`, marginLeft + 102, newY);
    newY += 5;
    pdf.text(`Tipo de Cuenta: ${bancoInfo.tipoCuenta}`, marginLeft + 102, newY);
    newY += 5;
    pdf.text(`N° de Cuenta: ${bancoInfo.cuenta}`, marginLeft + 102, newY);

    currentY += 65; // Ajustamos la posición para las tablas dinámicas

    // Verificamos si detallesCobro existe y es un array
    const detallesCobro = Array.isArray(documento.detalleCobro)
      ? documento.detalleCobro
      : [];

    // Generar tabla de Detalle Arriendos y/o Gasto Común si hay datos
    if (
      detallesCobro.filter(
        (detalle) =>
          detalle.glosa.includes("Arriendo") ||
          detalle.glosa.includes("Gasto Común") ||
          detalle.glosa.includes("Gasto Comun")
      ).length > 0
    ) {
      pdf.text("Detalle Arriendos y/o Gasto Común", marginLeft, currentY + 10);
      currentY += 15;
      pdf.autoTable({
        startY: currentY,
        head: [["Item", "Moneda", "Total UF", "Total $"]],
        body: detallesCobro
          .filter(
            (detalle) =>
              detalle.glosa.includes("Gasto Común") ||
              detalle.glosa.includes("Gasto Comun") ||
              detalle.glosa.includes("Arriendo")
          )
          .map((detalle) => [
            detalle.glosa,
            "CLP",
            formatNumberUf(detalle.unitario / valorUf).toLocaleString(),
            formatNumber(detalle.unitario).toLocaleString(),
          ]),
      });
      currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
    }

    // Generar tabla de Detalle Servicios si hay datos
    if (
      detallesCobro.filter(
        (detalle) =>
          detalle.glosa.includes("Agua") ||
          detalle.glosa.includes("Electricidad") ||
          detalle.glosa.includes("Internet")
      ).length > 0
    ) {
      pdf.text("Detalle Servicios", marginLeft, currentY);
      currentY += 5;
      pdf.autoTable({
        startY: currentY,
        head: [
          [
            "Item",
            "Lect. Inicial",
            "Lect. Final",
            "Consumo",
            "Unidad Medida",
            "Moneda",
            "Total",
          ],
        ],
        body: detallesCobro
          .filter((detalle) =>
            ["Agua", "Electricidad", "Internet"].some((servicio) =>
              detalle.glosa.includes(servicio)
            )
          )
          .map((detalle) => [
            detalle.glosa,
            detalle.lecturaInicial, // No tenemos datos de lecturas, así que ponemos un valor vacío
            detalle.lecturaFinal,
            detalle.cantidad,
            detalle.unidadMedida,
            "CLP",
            formatNumber(detalle.cantidad * detalle.unitario).toLocaleString(),
          ]),
      });
      currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
    }

    // Generar tabla de Detalle Obras Civiles e Instalaciones si hay datos
    if (
      detallesCobro.filter(
        (detalle) =>
          detalle.glosa.includes("Obra Civil") ||
          detalle.glosa.includes("Habilitación")
      ).length > 0
    ) {
      pdf.text("Detalle Obras Civiles e Instalaciones", marginLeft, currentY);
      currentY += 5;
      pdf.autoTable({
        startY: currentY,
        head: [["Item", "Moneda", "Cuotas", "Total UF", "Total $"]],
        body: detallesCobro
          .filter(
            (detalle) =>
              detalle.glosa.includes("Obra Civil") ||
              detalle.glosa.includes("Habilitacion")
          )
          .map((detalle) => [
            detalle.glosa,
            "CLP",
            "1", // Asumiendo que siempre es una cuota
            formatNumberUf(detalle.unitario / valorUf).toLocaleString(),
            formatNumber(detalle.unitario).toLocaleString(),
          ]),
      });
      currentY = pdf.lastAutoTable.finalY + 10; // Ajustamos la posición para la siguiente tabla
    }

    // Recuadro para Observaciones
    currentY += 10; // Ajustar la posición para que no se sobreponga
    const observacionesHeight = 35;
    pdf.setDrawColor(0, 0, 0);
    pdf.rect(marginLeft, currentY, 190, observacionesHeight);

    // Texto de Observaciones
    pdf.setFontSize(14);
    pdf.text("Observaciones:", marginLeft + 2, currentY + 5);
    pdf.setFontSize(11);
    pdf.text(
      "El cobro del gasto común incluye: \n - Vigilancia perimetral 24/7 \n - Iluminación espacios comunes \n - Mantención, mejoramiento y aseo áreas verdes \n - Mantención, mejoramiento y aseo áreas comúnes",
      marginLeft + 2,
      currentY + 11
    );

    // Abrir el PDF en una nueva ventana
    const pdfData = pdf.output("bloburl");
    window.open(pdfData);
  };

  const formatNumber = (number) => {
    if (typeof number !== "number") return number;
    return new Intl.NumberFormat("es-CL", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    }).format(number);
  };

  const formatNumberUf = (number) => {
    if (typeof number !== "number") return number;
    return new Intl.NumberFormat("es-CL", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "No disponible";
    const [year, month, day] = dateString.split("-");
    return `${day}-${month}-${year}`;
  };
  const [globalFilter, setGlobalFilter] = useState("");

  const documentosFiltrados = documentosData.filter((doc) => {
    if (estadoDocumento === "emitidos") {
      return doc.adjuntos && doc.adjuntos.length > 0; // Emitidos: tienen adjuntos
    } else if (estadoDocumento === "noEmitidos") {
      return !doc.adjuntos || doc.adjuntos.length === 0; // No emitidos: sin adjuntos
    }
    return true; // Todos: sin filtro
  });

  console.log(documentosFiltrados);

  return (
    <div>
      <h3>Documentos</h3>
      <div className="flex flex-column align-items-center justify-content-center mb-5 bg-surface-50">
        <div className="card shadow-4 surface-card border-round-md w-full max-w-lg">
          <h4 className="text-center text-primary mb-4">Buscar Documentos</h4>

          <div className="grid formgrid">
            <div className="field col-12 md:col-6 mb-4">
              <label
                htmlFor="empresa"
                className="block font-semibold mb-1 text-gray-600"
              >
                Empresa
              </label>
              <Dropdown
                inputId="empresa"
                aria-label="Seleccionar empresa"
                value={empresa}
                options={empresas.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setEmpresa(e.value)}
                optionLabel="label"
                optionValue="id"
                placeholder="Seleccione una empresa"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="field col-12 md:col-6 mb-4">
              <label
                htmlFor="documento"
                className="block font-semibold mb-1 text-gray-600"
              >
                Tipo de Documento
              </label>
              <Dropdown
                inputId="documento"
                aria-label="Seleccionar tipo de documento"
                value={documento}
                options={documentos.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setDocumento(e.value)}
                optionLabel="label"
                optionValue="codigo"
                placeholder="Seleccione un documento"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="field col-12 md:col-6 mb-4">
              <label
                htmlFor="mes"
                className="block font-semibold mb-1 text-gray-600"
              >
                Mes
              </label>
              <Dropdown
                inputId="mes"
                aria-label="Seleccionar mes"
                value={mes}
                options={meses.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setMes(e.value)}
                optionLabel="label"
                optionValue="mes"
                placeholder="Seleccione un mes"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="field col-12 md:col-6 mb-4">
              <label
                htmlFor="anio"
                className="block font-semibold mb-1 text-gray-600"
              >
                Año
              </label>
              <Dropdown
                inputId="anio"
                aria-label="Seleccionar año"
                value={anio}
                options={anios.map((option) => ({
                  ...option,
                  label: option.label.toUpperCase(),
                }))}
                onChange={(e) => setAnio(e.value)}
                placeholder="Seleccione un año"
                className="w-full p-2 border border-gray-300 rounded"
              />
            </div>

            <div className="col-12 text-center">
              <Button
                label="Buscar Documentos"
                aria-label="Buscar documentos"
                onClick={buscarDocumentos}
                className="p-button-raised p-button-primary w-full md:w-auto px-6 py-2 text-lg font-medium shadow-lg transition duration-150 ease-in-out "
                icon="pi pi-search"
              />
            </div>
          </div>
        </div>
      </div>

      {showTable && (
        <div className="p-fluid mt-3 mb-3" style={{ position: "relative" }}>
          <style>
            {`
        /* Card Container Grid */
        .card-container {
          display: grid;
          grid-template-columns: repeat(3, 1fr); /* 3 columns for desktop and tablet */
          gap: 1rem;
          align-items: stretch;
        }

        /* Mobile layout: 2x2x2 grid */
        @media (max-width: 768px) {
          .card-container {
            grid-template-columns: repeat(2, 1fr); /* 2 columns for mobile */
          }
        }

        /* Individual Card Styling */
        .responsive-card {
          background-color: #ffffff;
          padding: 1.5rem;
          border-radius: 0.5rem;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
          transition: transform 0.2s ease, box-shadow 0.2s ease;
          border-top: 4px solid #007bff; /* Primary border color */
          min-height: 150px;
        }

        /* Color accents for each card */
        .responsive-card:nth-child(1) { border-top-color: #28a745; } /* Green */
        .responsive-card:nth-child(2) { border-top-color: #007bff; } /* Blue */
        .responsive-card:nth-child(3) { border-top-color: #ffc107; } /* Yellow */
        .responsive-card:nth-child(4) { border-top-color: #dc3545; } /* Red */
        .responsive-card:nth-child(5) { border-top-color: #17a2b8; } /* Teal */
        .responsive-card:nth-child(6) { border-top-color: #6f42c1; } /* Purple */

        /* Hover Effect */
        .responsive-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
        }

        .responsive-card h1 {
          font-size: 1.4rem;
          margin: 0;
          color: #333;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .responsive-card span.label {
          font-weight: 600;
          color: #4a5568;
          font-size: 0.9rem;
          margin-bottom: 0.5rem;
        }

        .responsive-card span.description {
          font-size: 0.875rem;
          color: #666;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .responsive-card .value {
          font-size: 1.2rem;
          color: #333;
        }

        /* Icon Styling */
        .icon {
          font-size: 1.5rem;
          margin-bottom: 0.5rem;
        }

        /* Loading Overlay */
        .loading-overlay {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: rgba(255, 255, 255, 0.8);
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 0.5rem;
          z-index: 1;
        }

        /* Spinner Styling */
        .spinner {
          border: 4px solid rgba(0, 0, 0, 0.1);
          border-top: 4px solid #007bff;
          border-radius: 50%;
          width: 40px;
          height: 40px;
          animation: spin 1s linear infinite;
        }

        @keyframes spin {
          0% { transform: rotate(0deg); }
          100% { transform: rotate(360deg); }
        }
      `}
          </style>

          {/* Loading Overlay */}
          {isLoading && (
            <div className="loading-overlay">
              <div className="spinner"></div>
            </div>
          )}

          {/* Card Container */}
          <div className="card-container">
            {/* Cards */}
            <div className="responsive-card">
              <i className="icon pi pi-dollar"></i> {/* Currency icon */}
              <span className="label">Valor UF:</span>
              <h1 className="value">${formatNumberUf(uf)}</h1>
              <span className="description">
                Periodo {anio}-{mes}
              </span>
            </div>

            <div className="responsive-card">
              <i className="icon pi pi-file"></i> {/* Document icon */}
              <span className="label">Total Recaudación:</span>
              <h1 className="value">${formatNumber(importeTotal)}</h1>
              <span className="description">Total</span>
            </div>

            <div className="responsive-card">
              <i className="icon pi pi-send"></i> {/* Sent/issued icon */}
              <span className="label">Emitidos:</span>
              <h1 className="value">${formatNumber(totalEmitidos)}</h1>
              <span className="description">
                Por emitir: ${formatNumber(importeTotal - totalEmitidos)}
              </span>
            </div>

            <div className="responsive-card">
              <i className="icon pi pi-search"></i> {/* Search icon */}
              <span className="label">Documentos Encontrados</span>
              <h1 className="value">{documentosEncontrados}</h1>
              <span className="description">documentos</span>
            </div>

            <div className="responsive-card">
              <i className="icon pi pi-check-circle"></i>{" "}
              {/* Registered icon */}
              <span className="label">Documentos Emitidos</span>
              <h1 className="value">{documentosRegistrados}</h1>
              <span className="description">
                documentos con archivo adjunto
              </span>
            </div>

            <div className="responsive-card">
              <i className="icon pi pi-exclamation-triangle"></i>{" "}
              {/* Warning/pending icon */}
              <span className="label">Documentos por Emitir</span>
              <h1 className="value">{documentosPorRegistrar}</h1>
              <span className="description">
                documentos sin archivo adjunto
              </span>
            </div>
          </div>
        </div>
      )}

      {showTable && (
        <div className="p-mt-3">
          <style>
            {`
        /* Wrapper for rounded table shape */
        .table-wrapper {
          border-radius: 0.5rem;
          overflow: hidden;
          box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
          background-color: #ffffff;
        }

        /* Table Styling */
        .p-datatable {
          border: none;
          border-radius: 0.5rem;
        }
        .p-datatable .p-datatable-thead > tr > th {
          background-color: #e3f2fd; /* Light blue header */
          font-weight: 600;
          text-align: center;
          border-bottom: none;
        }

        .p-datatable .p-datatable-tbody > tr {
          transition: background-color 0.2s ease;
        }

        /* Row hover effect */
        .p-datatable .p-datatable-tbody > tr:hover {
          background-color: #f1f5f9;
        }

        /* Alternate row colors */
        .p-datatable .p-datatable-tbody > tr:nth-child(odd) {
          background-color: #ffffff;
        }
        .p-datatable .p-datatable-tbody > tr:nth-child(even) {
          background-color: #f9fafb;
        }

        /* Centering text */
        .p-datatable .p-column-header-content {
          justify-content: center;
        }

        /* Table cell padding and rounding */
        .p-datatable .p-datatable-tbody > tr > td {
          padding: 1rem;
          border-radius: 0.25rem;
        }

        /* Action Column Icons */
        .action-icons {
          display: flex;
          gap: 0.5rem;
        }

        /* Icon Styling */
        .icon {
          font-size: 1.5rem;
          color: #007bff;
          cursor: pointer;
          transition: color 0.2s ease;
        }
        .icon:hover {
          color: #0056b3; /* Darker blue on hover */
        }

        /* Responsive */
        .table-wrapper {
          overflow-x: auto;
        }

        /* Custom loading spinner */
        .custom-spinner {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
          width: 100%;
          font-size: 2rem;
          color: #007bff;
        }
      `}
          </style>

          {/* Global Filter Input */}
          <div className="inputgroup">
            <InputText
              placeholder="Buscar por Cliente"
              value={globalFilter}
              onChange={(e) => setGlobalFilter(e.target.value)}
              style={{
                borderRadius: "0.5rem",
                padding: "0.75rem 1rem",
                boxShadow: "0 2px 8px rgba(0, 123, 255, 0.2)",
                fontSize: "1rem",
              }}
            />
            <Dropdown
              id="estadoDocumento"
              value={estadoDocumento}
              options={[
                { label: "Todos", value: "todos" },
                { label: "Emitidos", value: "emitidos" },
                { label: "No Emitidos", value: "noEmitidos" },
              ]}
              style={{
                borderRadius: "0.5rem",
                padding: "0.75rem 1rem",
                boxShadow: "0 2px 8px rgba(0, 123, 255, 0.2)",
                fontSize: "1rem",
              }}
              onChange={(e) => setEstadoDocumento(e.value)}
              placeholder="Seleccione un estado"
              className="p-0"
            />
          </div>

          {/* Wrapper for Responsive Scroll */}
          <div className="table-wrapper">
            <DataTable
              className="p-datatable-gridlines"
              value={documentosFiltrados}
              paginator
              rows={10}
              loading={isLoading}
              emptyMessage="No se encontraron documentos"
              globalFilter={globalFilter}
              scrollable
              scrollDirection="both"
            >
              <Column field="id_documento" header="Id" sortable />
              <Column field="cliente" header="Cliente" sortable />
              <Column field="descripcion_grupo" header="Asignación" sortable />
              <Column field="empresa" header="Empresa" sortable />
              <Column field="rut_cliente" header="RUT Cliente" sortable />
              <Column field="documento" header="Documento" sortable />
              <Column
                field="n_documento"
                sortable
                header="N° Documento"
                body={(rowData) => (
                  <InputText
                    value={rowData.n_documento || ""}
                    onChange={(e) =>
                      onEditorValueChange(
                        { rowData, field: "n_documento" },
                        e.target.value
                      )
                    }
                    onBlur={actualizarFolioDocumento}
                    disabled={
                      rowData.documento.toLowerCase() === "nota de cobro"
                    }
                  />
                )}
              />
              <Column
                field="fecha_documento"
                header="Fecha Documento"
                sortable
              />
              <Column
                field="total_neto"
                header="Total Neto"
                sortable
                body={(rowData) => formatNumber(rowData.total_neto)}
              />
              <Column
                field="total_iva"
                header="Total IVA"
                sortable
                body={(rowData) => formatNumber(rowData.total_iva)}
              />
              <Column
                field="total_documento"
                header="Total Documento"
                sortable
                body={(rowData) => formatNumber(rowData.total_documento)}
              />
              <Column
                field="adjuntos"
                header="Acción"
                body={(rowData) => (
                  <div className="action-icons">
                    {detalleCobroTemplate(rowData)}
                    <FileUploadNew
                      rowData={rowData}
                      handleFileUpload={handleFileUpload}
                      bajarArchivo={bajarArchivo}
                      exportToPDF={exportToPDF}
                      isUploading={isUploading}
                    />
                  </div>
                )}
              />
            </DataTable>
          </div>
        </div>
      )}

      <Dialog
        header="Detalle del Cobro"
        visible={detalleVisible}
        style={{ width: "50vw" }}
        onHide={() => setDetalleVisible(false)}
      >
        <DataTable value={detalleCobro}>
          <Column field="glosa" header="Descripción Item" />
          <Column field="cantidad" header="Cantidad" />
          <Column field="unidadMedida" header="Unidad Medida" />
          <Column
            field="unitario"
            header="Valor Unitario"
            body={(rowData) => formatNumber(rowData.unitario)}
          />
          <Column field="lecturaInicial" header="Lectura Inicial" />
          <Column field="lecturaFinal" header="Lectura Final" />
        </DataTable>
      </Dialog>
    </div>
  );
};

export default Documentos;
